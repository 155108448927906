// src/components/Header.js
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./App.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <header className="header">
      <div className="header__title">
        <Link to="/" className="header__link" onClick={handleLinkClick}>
          Cardiology Reference
        </Link>
      </div>
      <div className="header__menu" onClick={toggleMenu}>
        <FaBars size={24} color="#fcf4f3" />
      </div>
      {isOpen && (
        <div className="header__dropdown">
          <Link
            to="/metscalculator"
            className="nav-link"
            onClick={handleLinkClick}
          >
            METS Calculator
          </Link>
          <Link
            to="/ecv-calculator"
            className="nav-link"
            onClick={handleLinkClick}
          >
            ECV Calculator
          </Link>
          <Link
            to="/guideline-assistant"
            className="nav-link"
            onClick={handleLinkClick}
          >
            Guideline Assistant
          </Link>
          <Link
            to="/fickcalculator"
            className="nav-link active"
            onClick={handleLinkClick}
          >
            Fick Calculator
          </Link>
          <Link
            to="/cmrflow"
            className="nav-link active"
            onClick={handleLinkClick}
          >
            CMR Flows
          </Link>
          <Link to="/disclaimer" className="nav-link" onClick={handleLinkClick}>
            Disclaimer
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;

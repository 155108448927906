// CMRFlow.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer"; // Import the Footer component (if applicable)
import { Container, Row, Col, Button } from "react-bootstrap";
import CollapsibleTab from "./CollapsibleTab";

const CMRFlow = () => {
  const [lvsv, setLvsv] = useState("");
  const [measurements, setMeasurements] = useState({
    aortaForwardFlow: [""],
    aortaDiastolicFlow: [""],
  });
  const [paMeasurements, setPaMeasurements] = useState({
    paForwardFlow: [""],
    paDiastolicFlow: [""],
  });
  const [rvStrokeVolume, setRvStrokeVolume] = useState("");

  const [qpQs, setQpQs] = useState(null);
  const [rVolMR, setRVolMR] = useState(null);
  const [rFracMR, setRFracMR] = useState(null);
  const [rVolAR, setRVolAR] = useState(null);
  const [rFracAR, setRFracAR] = useState(null);
  const [rVolTR, setRVolTR] = useState(null);
  const [rFracTR, setRFracTR] = useState(null);
  const [rVolPR, setRVolPR] = useState(null);
  const [rFracPR, setRFracPR] = useState(null);

  const [conditions, setConditions] = useState({
    aorticRegurgitation: false,
    mitralRegurgitation: false,
    tricuspidRegurgitation: false,
    pulmonicRegurgitation: false,
    atrialSeptalDefect: false,
  });

  const handleMeasurementChange = (type, index, value) => {
    const newMeasurements = { ...measurements };
    newMeasurements[type][index] = value;
    setMeasurements(newMeasurements);
  };

  const addFields = () => {
    if (measurements.aortaForwardFlow.length < 5) {
      setMeasurements({
        aortaForwardFlow: [...measurements.aortaForwardFlow, ""],
        aortaDiastolicFlow: [...measurements.aortaDiastolicFlow, ""],
      });
    }
  };

  const removeField = (index) => {
    if (measurements.aortaForwardFlow.length > 1) {
      setMeasurements({
        aortaForwardFlow: measurements.aortaForwardFlow.filter(
          (_, i) => i !== index
        ),
        aortaDiastolicFlow: measurements.aortaDiastolicFlow.filter(
          (_, i) => i !== index
        ),
      });
    }
  };

  const handlePaMeasurementChange = (type, index, value) => {
    const newPaMeasurements = { ...paMeasurements };
    newPaMeasurements[type][index] = value;
    setPaMeasurements(newPaMeasurements);
  };

  const addPaFields = () => {
    if (paMeasurements.paForwardFlow.length < 5) {
      setPaMeasurements({
        paForwardFlow: [...paMeasurements.paForwardFlow, ""],
        paDiastolicFlow: [...paMeasurements.paDiastolicFlow, ""],
      });
    }
  };

  const removePaField = (index) => {
    if (paMeasurements.paForwardFlow.length > 1) {
      setPaMeasurements({
        paForwardFlow: paMeasurements.paForwardFlow.filter(
          (_, i) => i !== index
        ),
        paDiastolicFlow: paMeasurements.paDiastolicFlow.filter(
          (_, i) => i !== index
        ),
      });
    }
  };

  const calculateMetrics = () => {
    // Utility function to check if all values in an array are valid numbers
    const allValidNumbers = (arr) =>
      arr.every((val) => !isNaN(parseFloat(val)) && isFinite(val));

    const LVSV = parseFloat(lvsv);
    const RVSV = parseFloat(rvStrokeVolume);

    const aortaForwardFlow = measurements.aortaForwardFlow.map(parseFloat);
    const aortaDiastolicFlow = measurements.aortaDiastolicFlow.map(parseFloat);

    const paForwardFlow = paMeasurements.paForwardFlow.map(parseFloat);
    const paDiastolicFlow = paMeasurements.paDiastolicFlow.map(parseFloat);

    // Validate based on conditions
    if (conditions.mitralRegurgitation) {
      if (
        !allValidNumbers(aortaForwardFlow) ||
        !allValidNumbers(aortaDiastolicFlow) ||
        isNaN(LVSV)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Mitral Regurgitation."
        );
        return;
      }
    }

    if (conditions.tricuspidRegurgitation) {
      if (
        !allValidNumbers(paForwardFlow) ||
        !allValidNumbers(paDiastolicFlow) ||
        isNaN(RVSV)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Tricuspid Regurgitation."
        );
        return;
      }
    }

    if (conditions.pulmonicRegurgitation) {
      if (
        !allValidNumbers(paForwardFlow) ||
        !allValidNumbers(paDiastolicFlow)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Pulmonic Regurgitation."
        );
        return;
      }
    }

    if (conditions.atrialSeptalDefect) {
      if (
        !allValidNumbers(aortaForwardFlow) ||
        !allValidNumbers(paForwardFlow)
      ) {
        alert(
          "Please enter valid numbers for all required fields for Atrial Septal Defect."
        );
        return;
      }
    }

    // Calculations
    const averageAortaForwardFlow =
      aortaForwardFlow.reduce((sum, val) => sum + val, 0) /
      aortaForwardFlow.length;
    const averageAortaDiastolicFlow =
      aortaDiastolicFlow.reduce((sum, val) => sum + val, 0) /
      aortaDiastolicFlow.length;
    const averagePAForwardFlow =
      paForwardFlow.reduce((sum, val) => sum + val, 0) / paForwardFlow.length;
    const averagePADiastolicFlow =
      paDiastolicFlow.reduce((sum, val) => sum + val, 0) /
      paDiastolicFlow.length;

    const qpQs = conditions.atrialSeptalDefect
      ? (averagePAForwardFlow / averageAortaForwardFlow).toFixed(1)
      : null;

    // Logic for Tricuspid Regurgitant Volume
    const RVolTR = conditions.tricuspidRegurgitation
      ? RVSV - (averagePAForwardFlow + averagePADiastolicFlow)
      : null;

    const RFracTR = conditions.tricuspidRegurgitation
      ? ((RVolTR / RVSV) * 100).toFixed(1)
      : null;

    const RVolAR = conditions.aorticRegurgitation
      ? aortaDiastolicFlow.reduce((sum, val) => sum + val, 0)
      : null;
    const RFracAR = conditions.aorticRegurgitation
      ? ((RVolAR / averageAortaForwardFlow) * 100).toFixed(1)
      : null;

    const RVolPR = conditions.pulmonicRegurgitation
      ? averagePADiastolicFlow
      : null;
    const RFracPR = conditions.pulmonicRegurgitation
      ? averagePAForwardFlow
        ? ((RVolPR / averagePAForwardFlow) * 100).toFixed(1)
        : "0.0"
      : null;

    // Logic for Mitral Regurgitant Volume
    const RVolMR = conditions.mitralRegurgitation
      ? LVSV - (averageAortaForwardFlow + averageAortaDiastolicFlow)
      : null;
    const RFracMR = conditions.mitralRegurgitation
      ? ((RVolMR / LVSV) * 100).toFixed(1)
      : null;

    // Validation for negative results
    if (
      RVolAR < 0 ||
      RFracAR < 0 ||
      RVolMR < 0 ||
      RFracMR < 0 ||
      RVolTR < 0 ||
      RFracTR < 0 ||
      RVolPR < 0 ||
      RFracPR < 0 ||
      qpQs < 0
    ) {
      alert("Undefined: parameter cannot be negative");
      return;
    }

    setRVolAR(RVolAR);
    setQpQs(qpQs);
    setRVolMR(RVolMR);
    setRFracMR(RFracMR);
    setRVolTR(RVolTR);
    setRFracTR(RFracTR);
    setRFracAR(RFracAR);
    setRVolPR(RVolPR);
    setRFracPR(RFracPR);
  };

  const toggleCondition = (condition) => {
    setConditions((prevConditions) => ({
      ...prevConditions,
      [condition]: !prevConditions[condition],
    }));
  };

  const links = [
    {
      href: "https://www.jacc.org/doi/full/10.1016/j.jacc.2017.12.009",
      text: "Use of Cardiac Magnetic Resonance Imaging in Assessing Mitral Regurgitation: Current Evidence",
    },
  ];

  return (
    <div className="page-container">
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={8} className="p-1">
            <Container fluid className="content">
              <div className="container">
                <h2 className="mb-4">CMR Flows Quantification</h2>

                {/* Condition Selection Buttons */}
                <div className="custom-button-row">
                  <Button
                    className={`circular-button ${
                      conditions.aorticRegurgitation ? "selected" : ""
                    }`}
                    onClick={() => toggleCondition("aorticRegurgitation")}
                  >
                    AR
                  </Button>
                  <Button
                    className={`circular-button ${
                      conditions.mitralRegurgitation ? "selected" : ""
                    }`}
                    onClick={() => toggleCondition("mitralRegurgitation")}
                  >
                    MR
                  </Button>
                  <Button
                    className={`circular-button ${
                      conditions.tricuspidRegurgitation ? "selected" : ""
                    }`}
                    onClick={() => toggleCondition("tricuspidRegurgitation")}
                  >
                    TR
                  </Button>
                  <Button
                    className={`circular-button ${
                      conditions.pulmonicRegurgitation ? "selected" : ""
                    }`}
                    onClick={() => toggleCondition("pulmonicRegurgitation")}
                  >
                    PR
                  </Button>
                  <Button
                    className={`circular-button ${
                      conditions.atrialSeptalDefect ? "selected" : ""
                    }`}
                    onClick={() => toggleCondition("atrialSeptalDefect")}
                  >
                    ASD
                  </Button>
                </div>

                <Container fluid className="content">
                  <div className="row mb-3">
                    {(conditions.aorticRegurgitation ||
                      conditions.mitralRegurgitation ||
                      conditions.atrialSeptalDefect) && (
                      <>
                        <div className="col-md-6">
                          <label
                            htmlFor="aortaForwardFlow"
                            className="form-label"
                          >
                            Aorta Forward Flow (mL):
                          </label>
                          {measurements.aortaForwardFlow.map(
                            (measurement, index) => (
                              <div key={index} className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={measurement}
                                  onChange={(e) =>
                                    handleMeasurementChange(
                                      "aortaForwardFlow",
                                      index,
                                      e.target.value
                                    )
                                  }
                                  placeholder="mL"
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="aortaDiastolicFlow"
                            className="form-label"
                          >
                            Aorta Diastolic Flow (mL):
                          </label>
                          {measurements.aortaDiastolicFlow.map(
                            (measurement, index) => (
                              <div key={index} className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={measurement}
                                  onChange={(e) =>
                                    handleMeasurementChange(
                                      "aortaDiastolicFlow",
                                      index,
                                      e.target.value
                                    )
                                  }
                                  placeholder="mL"
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div>
                          <Button
                            className="btn btn-primary"
                            onClick={addFields}
                          >
                            Add Aorta Flow Measurement
                          </Button>
                          <Button
                            className="btn btn-danger ms-2"
                            onClick={() =>
                              removeField(
                                measurements.aortaForwardFlow.length - 1
                              )
                            }
                          >
                            Remove Last Aorta Flow Measurement
                          </Button>
                        </div>
                      </>
                    )}

                    {(conditions.tricuspidRegurgitation ||
                      conditions.atrialSeptalDefect ||
                      conditions.pulmonicRegurgitation) && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="paForwardFlow" className="form-label">
                            PA Forward Flow (mL):
                          </label>
                          {paMeasurements.paForwardFlow.map(
                            (measurement, index) => (
                              <div key={index} className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={measurement}
                                  onChange={(e) =>
                                    handlePaMeasurementChange(
                                      "paForwardFlow",
                                      index,
                                      e.target.value
                                    )
                                  }
                                  placeholder="mL"
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="paDiastolicFlow"
                            className="form-label"
                          >
                            PA Diastolic Flow (mL):
                          </label>
                          {paMeasurements.paDiastolicFlow.map(
                            (measurement, index) => (
                              <div key={index} className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={measurement}
                                  onChange={(e) =>
                                    handlePaMeasurementChange(
                                      "paDiastolicFlow",
                                      index,
                                      e.target.value
                                    )
                                  }
                                  placeholder="mL"
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div>
                          <Button
                            className="btn btn-primary"
                            onClick={addPaFields}
                          >
                            Add PA Flow Measurement
                          </Button>
                          <Button
                            className="btn btn-danger ms-2"
                            onClick={() =>
                              removePaField(
                                paMeasurements.paForwardFlow.length - 1
                              )
                            }
                          >
                            Remove Last PA Flow Measurement
                          </Button>
                        </div>
                      </>
                    )}

                    {conditions.mitralRegurgitation && (
                      <div className="col-md-6 mt-3">
                        <label htmlFor="lvsv" className="form-label">
                          Left Ventricular Stroke Volume (Cine Short Axis):
                        </label>
                        <input
                          type="number"
                          id="lvsv"
                          className="form-control"
                          value={lvsv}
                          onChange={(e) => setLvsv(e.target.value)}
                          placeholder="mL"
                        />
                      </div>
                    )}

                    {conditions.tricuspidRegurgitation && (
                      <div className="col-md-6 mt-3">
                        <label htmlFor="rvStrokeVolume" className="form-label">
                          Right Ventricular Stroke Volume (Cine SAX):
                        </label>
                        <input
                          type="number"
                          id="rvStrokeVolume"
                          className="form-control"
                          value={rvStrokeVolume}
                          onChange={(e) => setRvStrokeVolume(e.target.value)}
                          placeholder="mL"
                        />
                      </div>
                    )}
                  </div>
                </Container>

                <Button
                  className="btn btn-primary ms-2"
                  onClick={calculateMetrics}
                >
                  Calculate Flows
                </Button>

                <div className="mt-3">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      {conditions.aorticRegurgitation && rVolAR !== null && (
                        <div>
                          <h5>
                            Aortic Regurgitant Volume:{" "}
                            <strong>{rVolAR} mL</strong>
                          </h5>
                          <h5>
                            Aortic Regurgitant Fraction:{" "}
                            <strong>{rFracAR}%</strong>
                          </h5>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      {conditions.mitralRegurgitation && rVolMR !== null && (
                        <div>
                          <h5>
                            Mitral Regurgitant Volume:{" "}
                            <strong>{rVolMR} mL</strong>
                          </h5>
                          <h5>
                            Mitral Regurgitant Fraction:{" "}
                            <strong>{rFracMR}%</strong>
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      {conditions.tricuspidRegurgitation && rVolTR !== null && (
                        <div>
                          <h5>
                            Tricuspid Regurgitant Volume:{" "}
                            <strong>{rVolTR} mL</strong>
                          </h5>
                          <h5>
                            Tricuspid Regurgitant Fraction:{" "}
                            <strong>{rFracTR}%</strong>
                          </h5>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      {conditions.pulmonicRegurgitation && rVolPR !== null && (
                        <div>
                          <h5>
                            Pulmonic Regurgitant Volume:{" "}
                            <strong>{rVolPR} mL</strong>
                          </h5>
                          <h5>
                            Pulmonic Regurgitant Fraction:{" "}
                            <strong>{rFracPR}%</strong>
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  {conditions.atrialSeptalDefect && qpQs !== null && (
                    <div>
                      <h5>
                        Qp:Qs: <strong>{qpQs}</strong>
                      </h5>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <Link to="/" className="btn btn-secondary">
                    Home
                  </Link>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid></Container>
      <CollapsibleTab links={links} />
      {/* Footer component can be added here if needed */}
      {/* <Footer /> */}
    </div>
  );
};

export default CMRFlow;

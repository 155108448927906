// src/Home.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "./Menu";
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="page-container">
      <Container fluid className="content">
        <Row>
          <Col sm={2} className="">
            {/* Width for Menu */}
            <Menu />
          </Col>
          <Col xs={9} className="p-1">
            {" "}
            {/* Main content area */}
            {/* Add other content here */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;

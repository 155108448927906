// src/Menu.js
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "./App.css"; // Custom styles (optional)

const Menu = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={12} className="bg-light p-1">
          {" "}
          {/* Adjust width as needed */}
          <ul className="nav nav-pills flex-column">
            <li className="nav-item my-1">
              <Link to="/metscalculator" className="nav-link active">
                METs Calculator
              </Link>
            </li>
            <li className="nav-item my-1">
              <Link to="/ecv-calculator" className="nav-link active">
                Myocardial ECV Calculator
              </Link>
            </li>
            <li className="nav-item my-1">
              <Link to="/guideline-assistant" className="nav-link active">
                Guideline Assistant
              </Link>
            </li>
            <li className="nav-item my-1">
              <Link to="/fickcalculator" className="nav-link active">
                Fick Calculator
              </Link>
            </li>
            <li className="nav-item my-1">
              <Link to="/cmrflow" className="nav-link active">
                CMR Flows
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={9}>
          {" "}
          {/* Main content area */}
          {/* Place the rest of the content here */}
        </Col>
      </Row>
    </Container>
  );
};

export default Menu;

// CollapsibleTab.js
import React, { useState } from "react";
import "./App.css"; // Import your CSS for styling

const CollapsibleTab = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={`collapsible-tab ${isOpen ? "open" : "closed"}`}>
      <button onClick={toggleTab} className="toggle-button">
        {isOpen ? "Collapse" : "References"}
      </button>
      {isOpen && (
        <div className="content">
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CollapsibleTab;

// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Calculator from "./Calculator";
import ECVCalculator from "./ECVCalculator";
import METsCalculator from "./METsCalculator";
import Header from "./Header";
import Footer from "./Footer";
import GuidelineAssistant from "./GuidelineAssistant"; // Ensure correct import
import Disclaimer from "./Disclaimer";
import FickCalculator from "./FickCalculator";

import "bootstrap/dist/css/bootstrap.min.css";
import CMRFlow from "./CMRFlow";

const App = () => {
  return (
    <Router>
      <div>
        <Header /> {/* Place Header here to be visible across all pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/metscalculator" element={<METsCalculator />} />
          <Route path="/ecv-calculator" element={<ECVCalculator />} />
          <Route path="/guideline-assistant" element={<GuidelineAssistant />} />
          <Route path="/fickcalculator" element={<FickCalculator />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/cmrflow" element={<CMRFlow />} />
        </Routes>
        <Footer /> {/* Place Footer here to be visible across all pages */}
      </div>
    </Router>
  );
};

export default App;

// src/METsCalculator.js
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Menu from "./Menu";
import Footer from "./Footer";
import { Container, Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";
import CollapsibleTab from "./CollapsibleTab";

const METsCalculator = () => {
  const [exerciseDuration, setExerciseDuration] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("male");
  const [protocol, setProtocol] = useState("standard");
  const [metsResult, setMetsResult] = useState("");
  const [fitnessCategory, setFitnessCategory] = useState("");

  const links = [
    {
      href: "https://www.acsm.org/education-resources/books/guidelines-exercise-testing-prescription",
      text: "ACSM Guidelines for Exercise Testing and Prescription",
    },
  ];

  const bruceProtocolMETs = {
    standard: {
      0: 1,
      3: 4.6,
      6: 7,
      9: 10.2,
      12: 13.5,
      15: 14.9,
      18: 17,
      21: 19.3,
    },
  };

  const modifiedBruceProtocolMETs = {
    0: 1,
    3: 2.3,
    6: 3.5,
    9: 4.6,
    12: 7,
    15: 10.2,
    18: 13.5,
    21: 14.9,
    24: 17,
    27: 19.3,
  };

  const metThresholds = {
    male: {
      20: [15.4, 13.9, 12.8, 11.7, 10.5, 0],
      30: [14.8, 13.4, 12.5, 11.3, 10.1, 0],
      40: [14.2, 12.8, 11.7, 10.7, 9.7, 0],
      50: [13.4, 11.9, 10.9, 9.9, 8.8, 0],
      60: [12.2, 10.9, 10.0, 9.0, 7.8, 0],
      70: [11.3, 10.1, 9.0, 8.1, 7.0, 0],
      80: [11.3, 10.1, 9.0, 8.1, 7.0, 0],
    },
    female: {
      20: [13.4, 12.1, 11.0, 10.1, 8.8, 0],
      30: [12.9, 11.7, 10.5, 9.7, 8.4, 0],
      40: [12.3, 11.0, 10.1, 9.2, 8.1, 0],
      50: [11.1, 10.1, 9.2, 8.4, 7.4, 0],
      60: [10.3, 9.2, 8.4, 7.6, 6.8, 0],
      70: [9.3, 8.5, 8.0, 7.2, 6.3, 0],
      80: [9.3, 8.5, 8.0, 7.2, 6.3, 0],
    },
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    const validInput = /^([0-9]{0,2}):?[0-9]{0,2}$/;
    if (value === "" || validInput.test(value)) {
      setExerciseDuration(value);
    }
  };

  const handleAgeChange = (e) => {
    const ageValue = parseInt(e.target.value);
    if (!isNaN(ageValue)) {
      setAge(ageValue);
    } else {
      setAge("");
    }
  };

  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender);
  };

  const handleProtocolChange = (selectedProtocol) => {
    setProtocol(selectedProtocol);
  };

  const calculateMETs = () => {
    const [minutes, seconds] = exerciseDuration.split(":").map(Number);
    const minutesNum = isNaN(minutes) ? 0 : minutes;
    const secondsNum = isNaN(seconds) ? 0 : seconds;
    const totalDuration = minutesNum + secondsNum / 60;

    let metValue = 0;

    const protocolMETs =
      protocol === "standard"
        ? bruceProtocolMETs.standard
        : modifiedBruceProtocolMETs;

    for (let i = 0; i < Object.keys(protocolMETs).length; i++) {
      const x0 = parseFloat(Object.keys(protocolMETs)[i]);
      const y0 = protocolMETs[x0];
      const x1 = parseFloat(Object.keys(protocolMETs)[i + 1] || totalDuration);
      const y1 = protocolMETs[x1] || y0;
      if (totalDuration <= x1) {
        const proportion = (totalDuration - x0) / (x1 - x0);
        metValue = y0 + proportion * (y1 - y0);
        break;
      }
    }

    setMetsResult(metValue.toFixed(2));

    if (age < 20) {
      setFitnessCategory("Age less than 20: Fitness category not available");
    } else {
      const decade = Math.floor(age / 10) * 10;
      const thresholds = metThresholds[gender][decade];
      let category = "";
      if (metValue >= thresholds[0]) {
        category = "Superior";
      } else if (metValue >= thresholds[1]) {
        category = "Excellent";
      } else if (metValue >= thresholds[2]) {
        category = "Good";
      } else if (metValue >= thresholds[3]) {
        category = "Fair";
      } else if (metValue >= thresholds[4]) {
        category = "Poor";
      } else {
        category = "Very poor";
      }
      setFitnessCategory(category);
    }
  };

  const chartData = useMemo(() => {
    const protocolMETs =
      protocol === "standard"
        ? bruceProtocolMETs.standard
        : modifiedBruceProtocolMETs;

    const data = [];
    const xValues = Object.keys(protocolMETs).map(Number);
    const yValues = xValues.map((x) => protocolMETs[x]);

    // Interpolate values between the given points
    for (let i = 0; i < xValues.length - 1; i++) {
      const x0 = xValues[i];
      const y0 = yValues[i];
      const x1 = xValues[i + 1];
      const y1 = yValues[i + 1];

      // Add data points between x0 and x1
      for (let x = x0; x <= x1; x += 0.1) {
        // Use finer steps for smoother curve
        const proportion = (x - x0) / (x1 - x0);
        const y = y0 + proportion * (y1 - y0);
        data.push({ x, y });
      }
    }

    // Add the last point
    data.push({
      x: xValues[xValues.length - 1],
      y: yValues[yValues.length - 1],
    });

    return data;
  }, [protocol]);

  const extractColor = (element) => {
    const thresholds = metThresholds[gender][Math.floor(age / 10) * 10] || [];
    if (element.y >= thresholds[0]) return "#ace9e9"; // Superior
    if (element.y >= thresholds[1]) return "#66ffcc"; // Excellent
    if (element.y >= thresholds[2]) return "#ccffcc"; // Good
    if (element.y >= thresholds[3]) return "#ffffcc"; // Fair
    if (element.y >= thresholds[4]) return "#ffcc66"; // Poor
    return "#ff9933"; // Very poor
  };

  const dataSets = [];
  let previousColor = null;

  for (const element of chartData.sort((a, b) => a.x - b.x)) {
    const color = extractColor(element);

    let dataSet;
    if (dataSets.length === 0 || previousColor !== color) {
      const previousDataSet =
        dataSets.length !== 0 ? dataSets[dataSets.length - 1] : null;
      dataSet = {
        name: `METs ${fitnessCategory}`,
        color: color,
        data:
          previousDataSet === null
            ? []
            : [previousDataSet.data[previousDataSet.data.length - 1]],
      };
      dataSets.push(dataSet);
    } else {
      dataSet = dataSets[dataSets.length - 1];
    }

    dataSet.data.push([element.x, element.y]);
    previousColor = color;
  }

  const calculateXAxisMax = () => {
    const [minutes, seconds] = exerciseDuration.split(":").map(Number);
    const minutesNum = isNaN(minutes) ? 0 : minutes;
    const totalDuration = minutesNum + (isNaN(seconds) ? 0 : seconds / 60);
    return totalDuration + 3; // Add 3 minutes
  };

  const calculateYAxisMax = () => {
    const [minutes, seconds] = exerciseDuration.split(":").map(Number);
    const minutesNum = isNaN(minutes) ? 0 : minutes;
    const totalDuration = minutesNum + (isNaN(seconds) ? 0 : seconds / 60);

    let metValue = 0;

    const protocolMETs =
      protocol === "standard"
        ? bruceProtocolMETs.standard
        : modifiedBruceProtocolMETs;

    // Add 3 minutes to the totalDuration
    const extendedDuration = totalDuration + 3;

    // Compute METs for the extended duration
    for (let i = 0; i < Object.keys(protocolMETs).length; i++) {
      const x0 = parseFloat(Object.keys(protocolMETs)[i]);
      const y0 = protocolMETs[x0];
      const x1 = parseFloat(
        Object.keys(protocolMETs)[i + 1] || extendedDuration
      );
      const y1 = protocolMETs[x1] || y0;
      if (extendedDuration <= x1) {
        const proportion = (extendedDuration - x0) / (x1 - x0);
        metValue = y0 + proportion * (y1 - y0);
        break;
      }
    }

    return metValue;
  };

  const getAnnotation = () => {
    const [minutes, seconds] = exerciseDuration.split(":").map(Number);
    const minutesNum = isNaN(minutes) ? 0 : minutes;
    const secondsNum = isNaN(seconds) ? 0 : seconds;
    const totalDuration = minutesNum + secondsNum / 60;

    let metValue = 0;
    const protocolMETs =
      protocol === "standard"
        ? bruceProtocolMETs.standard
        : modifiedBruceProtocolMETs;

    for (let i = 0; i < Object.keys(protocolMETs).length; i++) {
      const x0 = parseFloat(Object.keys(protocolMETs)[i]);
      const y0 = protocolMETs[x0];
      const x1 = parseFloat(Object.keys(protocolMETs)[i + 1] || totalDuration);
      const y1 = protocolMETs[x1] || y0;
      if (totalDuration <= x1) {
        const proportion = (totalDuration - x0) / (x1 - x0);
        metValue = y0 + proportion * (y1 - y0);
        break;
      }
    }

    return {
      x: totalDuration,
      y: metValue,
    };
  };

  const annotation = getAnnotation();

  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      text: "METs Chart",
      align: "center",
      style: {
        fontFamily: "Poppins, sans-serif",
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        // Only display METs value in the custom tooltip
        const value = series[seriesIndex][dataPointIndex];
        return `
          <div style="fontFamily: 'Poppins, sans-serif'; padding: 10px; background: #fff; color: #000; border: 1px solid #ddd; border-radius: 4px;">
            METs: ${value.toFixed(1)}
          </div>
        `;
      },
      style: {
        fontFamily: "Poppins, sans-serif",
      },
    },
    legend: {
      show: false, // Hide chart legend as custom legend is used
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth", // Ensure the line is smooth
      colors: ["silver"],
      width: 4,
    },
    fill: {
      colors: [(series) => dataSets[series.seriesIndex]?.color || "black"],
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 1.0,
        stops: [0, 95, 100],
      },
    },
    xaxis: {
      type: "numeric",
      labels: {
        formatter: (val) => {
          // Format the value and add " minutes" text
          return `${parseFloat(val).toFixed(1)} minutes`;
        },
        style: {
          fontFamily: "Poppins, sans-serif",
          colors: "#000", // Set text color to match tooltip
          fontSize: "12px", // Adjust font size if needed
        },
      },
      title: {
        text: "Minutes",
      },
      style: {
        fontFamily: "Poppins, sans-serif",
      },
      max: calculateXAxisMax(), // Set x-axis max dynamically
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(1), // Format y-axis labels to 1 decimal place
      },
      axisBorder: {
        show: true,
      },
      title: {
        text: "METs",
      },
      style: {
        fontFamily: "Poppins, sans-serif",
      },
      max: calculateYAxisMax(), // Set y-axis max dynamically
      min: 0,
    },
    annotations: {
      points: [
        {
          x: annotation.x,
          y: annotation.y,
          seriesIndex: 0,
          label: {
            borderColor: "",
            offsetY: -5,
            offsetX: -75,
            style: {
              color: "#black",
              background: "white",
              fontFamily: "Poppins, sans-serif",
              fontSize: "14px",
              border: "2px solid #ddd", // Border color
              borderRadius: "4px", // Border radius for rounded corners
              boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.5)", // Enhanced shadow effect
            },
            text: `Duration: ${exerciseDuration},  \nMETs: ${metsResult}`,
          },
        },
      ],
    },
  };

  // Create a legend based on the fitness categories and their colors
  const legendItems = [
    { label: "Very poor", color: "#ff9933" },
    { label: "Poor", color: "#ffcc66" },
    { label: "Fair", color: "#ffffcc" },
    { label: "Good", color: "#ccffcc" },
    { label: "Excellent", color: "#66ffcc" },
    { label: "Superior", color: "#ace9e9" },
  ];

  return (
    <div className="page-container">
      <Container fluid className="content">
        <Row>
          <Col sm={2} className="">
            {/* Width for Menu */}
            <Menu />
          </Col>
          <Container fluid className="content">
            <div className="container">
              <h2 className="mb-4">METs Calculator</h2>
              <div className="mb-3">
                <label htmlFor="duration" className="form-label">
                  Exercise Duration:
                </label>
                <input
                  type="text"
                  id="duration"
                  className="form-control"
                  value={exerciseDuration}
                  onChange={handleDurationChange}
                  placeholder="MM:SS"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="age" className="form-label">
                  Age:
                </label>
                <input
                  type="number"
                  id="age"
                  className="form-control"
                  value={age}
                  onChange={handleAgeChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">
                  Gender:
                </label>
                <select
                  id="gender"
                  className="form-select"
                  value={gender}
                  onChange={(e) => handleGenderChange(e.target.value)}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="protocol" className="form-label">
                  Protocol:
                </label>
                <select
                  id="protocol"
                  className="form-select"
                  value={protocol}
                  onChange={(e) => handleProtocolChange(e.target.value)}
                >
                  <option value="standard">Standard</option>
                  <option value="modified">Modified</option>
                </select>
              </div>
              <button className="btn btn-primary" onClick={calculateMETs}>
                Calculate METs
              </button>
              {metsResult && (
                <div className="mt-3">
                  <h4>
                    METs Achieved: <strong>{metsResult}</strong>
                  </h4>
                </div>
              )}
              {fitnessCategory && (
                <div className="mt-3">
                  <h4>
                    ACSM Fitness Category: <strong>{fitnessCategory}</strong>
                  </h4>
                </div>
              )}
            </div>
            <div className="container">
              <div className="chart-container mt-4">
                <Chart
                  type="area"
                  width="100%"
                  height="400"
                  options={options}
                  series={dataSets}
                />
              </div>
              <div className="mt-4">
                <div className="legend-container">
                  <div className="legend">
                    {legendItems.map((item) => (
                      <div className="legend-item" key={item.label}>
                        <span
                          className="legend-color"
                          style={{ backgroundColor: item.color }}
                        ></span>
                        <span className="legend-label">{item.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Row>
      </Container>
      <CollapsibleTab links={links} />
    </div>
  );
};

export default METsCalculator;

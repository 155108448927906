// src/GuidelineAssistant.js
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import { Container, Row, Col } from "react-bootstrap";

const guidelinesData = [
  {
    text: "For patients with hypertrophic cardiomyopathy or at risk for hypertrophic cardiomyopathy, shared decision-making is recommended in developing a plan of care (including, but not limited to, decisions regarding genetic evaluation, activity, lifestyle, and therapy choices) that includes a full disclosure of the risks, benefits, and anticipated outcomes of all options, as well as the opportunity for the patient and caregivers to express their goals and concerns.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy in whom septal reduction therapy is indicated, the procedure should be performed at experienced centers (comprehensive or primary HCM centers) with demonstrated excellence in clinical outcomes for these procedures.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For children and adolescents with hypertrophic cardiomyopathy, a comprehensive, systematic noninvasive SCD risk assessment at initial evaluation and every 1 to 2 years thereafter is recommended and should include evaluation of these risk factors: personal history of cardiac arrest or sustained ventricular arrhythmias, personal history of syncope suspected by clinical history to be arrhythmic, family history in close relatives of premature HCM-related sudden death, cardiac arrest, or sustained ventricular arrhythmias, maximal LV wall thickness, EF, LV apical aneurysm, and NSVT episodes on continuous ambulatory electrocardiographic monitoring.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy who are receiving an ICD, either a single-chamber transvenous ICD or a subcutaneous ICD is recommended after a shared decision-making discussion that takes into consideration patient preferences, age, lifestyle, and potential need for pacing for bradycardia or VT termination.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy who are receiving a transvenous ICD, single-coil ICD leads are recommended in preference to dual-coil leads, if defibrillation threshold is deemed adequate.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For patients with hypertrophic cardiomyopathy who have persistent symptoms attributable to left ventricular outflow tract obstruction despite beta blockers or nondihydropyridine calcium channel blockers, adding a myosin inhibitor (for adult patients only), disopyramide (in combination with an atrioventricular nodal blocking agent), or septal reduction therapy performed at experienced centers is recommended.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For patients with hypertrophic cardiomyopathy, participation in high-intensity recreational activities or moderate- to high-intensity competitive sports activities may be considered after a comprehensive evaluation and shared discussion, repeated annually with an expert provider who conveys that the risk of sudden death and ICD shocks may be increased, and with the understanding that eligibility decisions for competitive sports participation often involve third parties acting on behalf of the schools or teams.",
    class: "Class IIa",
    source: "ACC/AHA",
  },
  {
    text: "In selected adult patients with nonobstructive hypertrophic cardiomyopathy receiving an ICD who have NYHA class II to ambulatory class IV heart failure, left bundle branch block, and left ventricular ejection fraction <50%, cardiac resynchronization therapy for symptom reduction is reasonable.",
    class: "Class IIa",
    source: "ACC/AHA",
  },
  {
    text: "For patients aged 16 years or older with hypertrophic cardiomyopathy and with one or more major sudden cardiac death risk factors, discussion of the estimated 5-year sudden death risk and mortality rates can be useful during the shared decision-making process for ICD placement.",
    class: "Class IIa",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy who are receiving an ICD, dual-chamber ICDs are reasonable for patients with a need for atrial or atrioventricular sequential pacing for bradycardia/conduction abnormalities, or as an attempt to relieve symptoms of obstructive hypertrophic cardiomyopathy (most commonly in patients >65 years of age).",
    class: "Class IIa",
    source: "ACC/AHA",
  },
  {
    text: "In patients with hypertrophic cardiomyopathy who develop persistent systolic dysfunction (LVEF <50%), cardiac myosin inhibitors should be discontinued.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For patients with hypertrophic cardiomyopathy who develop atrial fibrillation, anticoagulation with direct-acting oral anticoagulants (DOACs) or alternatively warfarin should be considered irrespective of the CHA2DS2-VASc score.",
    class: "Class I",
    source: "ACC/AHA",
  },
  {
    text: "For patients with hypertrophic cardiomyopathy who are capable of a high level of physical performance, participation in competitive sports may be considered after review by an expert provider with experience managing athletes with hypertrophic cardiomyopathy who conducts an annual comprehensive evaluation and shared decision-making that balances potential benefits and risks.",
    class: "Class IIb",
    source: "ACC/AHA",
  },
  {
    text: "For patients with hypertrophic cardiomyopathy, consultation with or referral to a comprehensive or primary HCM center is reasonable to aid in complex disease-related management decisions.",
    class: "Class IIa",
    source: "ACC/AHA",
  },
];

const GuidelineAssistant = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredGuidelines = guidelinesData.filter((guideline) => {
    const searchWords = searchTerm.trim().split(/\s+/); // Split searchTerm into words
    return searchWords.every((word) =>
      guideline.text.toLowerCase().includes(word)
    );
  });

  return (
    <div className="page-container">
      <Container fluid>
        <Row>
          <Col sm={2} className="">
            {/* Width for Menu */}
            <Menu />
          </Col>
          <Col xs={9} className="p-1">
            <Row className="justify-content-center">
              <Col xs={12} md={8} className="text-center">
                <h2 className="mb-4">Guideline Assistant</h2>
                <input
                  type="text"
                  placeholder="Search guidelines..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="form-control mx-auto mb-3"
                />
              </Col>
            </Row>
            {/* Conditionally render the guidelines */}
            {searchTerm && (
              <Row>
                <Col>
                  <div>
                    {filteredGuidelines.length > 0 ? (
                      filteredGuidelines.map((guideline, index) => (
                        <div key={index} className="guideline mb-3 p-2 border">
                          <p>
                            <strong>Text:</strong> {guideline.text}
                          </p>
                          <p>
                            <strong>Class:</strong> {guideline.class}
                          </p>
                          <p>
                            <strong>Source:</strong> {guideline.source}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p>No guidelines found</p>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GuidelineAssistant;
